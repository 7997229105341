(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['Profile.hbs'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='fullscreen-container'>\n    <div class='center-container'>\n        <div class='profile'>\n            <div class='profile_header'>\n                <div class='profile_back_btn'>\n                    <button id='backButton'>&lt;</button>\n                </div>\n                <h2 class='text_center profile_title'>Профиль</h2>\n            </div>\n            <div class='flex_center'>\n                <div class='img-circle'>\n                    <picture>\n                        <img\n                            id='profile-avatar'\n                            src='"
    + alias4(((helper = (helper = lookupProperty(helpers,"avatar") || (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":16,"column":33},"end":{"line":16,"column":43}}}) : helper)))
    + "'\n                            alt='Не удалось загрузить фотографию профиля'\n                        />\n                    </picture>\n                </div>\n            </div>\n            <p class='text_center profile_field' id='profile__name'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":68},"end":{"line":22,"column":76}}}) : helper)))
    + "\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"surname") || (depth0 != null ? lookupProperty(depth0,"surname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"surname","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":27}}}) : helper)))
    + "</p>\n            <p\n                class='text_center profile_field'\n                id='profile__username'\n            >@"
    + alias4(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":27,"column":14},"end":{"line":27,"column":26}}}) : helper)))
    + "</p>\n            <p\n                class='text_center profile_field'\n                id='profile__email'\n            >"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":31,"column":13},"end":{"line":31,"column":22}}}) : helper)))
    + "</p>\n            <p\n                class='text_center profile_description'\n                id='profile__about'\n            >"
    + alias4(((helper = (helper = lookupProperty(helpers,"about") || (depth0 != null ? lookupProperty(depth0,"about") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"about","hash":{},"data":data,"loc":{"start":{"line":35,"column":13},"end":{"line":35,"column":22}}}) : helper)))
    + "</p>\n            <div class='flex_center'>\n                <button id='editButton'>Редактировать профиль</button>\n            </div>\n            <div class='flex_center'>\n                <button id='changePasswordButton'>Изменить пароль</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
})();