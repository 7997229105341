(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['Sticker.hbs'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='sticker_wrapper'>\n    <video id='sticker_img_"
    + alias4(((helper = (helper = lookupProperty(helpers,"sticker_id") || (depth0 != null ? lookupProperty(depth0,"sticker_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sticker_id","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":41}}}) : helper)))
    + "' class='sticker_img' onmouseover=\"this.play()\" onmouseout=\"this.pause();this.currentTime=0;\">\n        <source \n            src='"
    + alias4(((helper = (helper = lookupProperty(helpers,"sticker_path") || (depth0 != null ? lookupProperty(depth0,"sticker_path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sticker_path","hash":{},"data":data,"loc":{"start":{"line":4,"column":17},"end":{"line":4,"column":33}}}) : helper)))
    + "'\n            alt='"
    + alias4(((helper = (helper = lookupProperty(helpers,"sticker_desc") || (depth0 != null ? lookupProperty(depth0,"sticker_desc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sticker_desc","hash":{},"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":33}}}) : helper)))
    + "'>\n        </source>\n    </video>\n</div>";
},"useData":true});
})();