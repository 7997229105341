(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['Message.hbs'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "my_message";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"file") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "' download>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"file") : depth0)) != null ? lookupProperty(stack1,"original_name") : stack1), depth0))
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <video\n                class='message_sticker'\n                src='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sticker_path") || (depth0 != null ? lookupProperty(depth0,"sticker_path") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sticker_path","hash":{},"data":data,"loc":{"start":{"line":14,"column":21},"end":{"line":14,"column":37}}}) : helper)))
    + "'\n                onmouseover='this.play()'\n                onmouseout='this.pause();this.currentTime=0;'\n            />\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <text class='time'>изменено</text>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\n                class='delete-button'\n                id='delete-message-button_"
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":40,"column":42},"end":{"line":40,"column":56}}}) : helper)))
    + "'\n            >\n                Удалить\n            </button>\n            <button class='edit-button' id='edit-message-button_"
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":44,"column":64},"end":{"line":44,"column":78}}}) : helper)))
    + "'>\n                Изменить\n            </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\n            class='translate-button'\n            id='translate-message-button_"
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":50,"column":41},"end":{"line":50,"column":55}}}) : helper)))
    + "'\n            >\n                Перевести\n            </button>\n            <button class='shortly-button' id='shortly-message-button_"
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":54,"column":70},"end":{"line":54,"column":84}}}) : helper)))
    + "'>\n                Кратко\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='message "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_my_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":58}}})) != null ? stack1 : "")
    + "' id='message_id_"
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":75},"end":{"line":1,"column":89}}}) : helper)))
    + "'>\n    <div class='author'>\n        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":28}}}) : helper)))
    + "</strong>\n    </div>\n\n    <div class='message_data'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"file") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sticker_path") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class='message_text_wrapper'>\n            <span class='message_text'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"message_text") || (depth0 != null ? lookupProperty(depth0,"message_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_text","hash":{},"data":data,"loc":{"start":{"line":21,"column":39},"end":{"line":21,"column":55}}}) : helper)))
    + "</span>\n            <input\n                type='text'\n                class='edit_message_input'\n                style='display: none;'\n            />\n        </div>\n    </div>\n\n    <button class='inline_button origin-button hidden'>Назад</button>\n    <text class='time'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"sent_at") || (depth0 != null ? lookupProperty(depth0,"sent_at") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sent_at","hash":{},"data":data,"loc":{"start":{"line":31,"column":23},"end":{"line":31,"column":34}}}) : helper)))
    + "</text>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edited") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class='messageModal hidden'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_my_message") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});
})();