(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['Chat.hbs'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<main>\n    <div class='chat_header'>\n        <div class='chat_header_back'>&#8592;</div>\n        <h2 id='chat_header'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":39}}}) : helper)))
    + "</h2>\n        <div class='search_container' id='search_container_message'>\n        </div>\n    </div>\n    <div class='chat'>\n        <div class='chat_width message_list' id='active-chat-container'></div>\n        <div id='chat_input_block'>\n<!--            <button class='chat_width chat-input' id='join_channel' style=\"display: none;\">Подписаться на канал</button>-->\n<!--            <button class='chat_width chat-input' id='leave_channel' style=\"display: none;\">Покинуть канал</button>-->\n<!--            -->\n<!--            <button class='input_attach chat-input' disabled>*скрепка*</button>-->\n<!--            <input-->\n<!--                id='input_message'-->\n<!--                class='input_text chat-input'-->\n<!--                type='text'-->\n<!--                maxlength='512'-->\n<!--                placeholder='Сообщение...'-->\n<!--                autocomplete='off'-->\n<!--                value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"inputMessageValue") || (depth0 != null ? lookupProperty(depth0,"inputMessageValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputMessageValue","hash":{},"data":data,"loc":{"start":{"line":22,"column":27},"end":{"line":22,"column":48}}}) : helper)))
    + "'-->\n<!--            />-->\n<!--            <button class='input_send chat-input'>→</button>-->\n        </div>\n    </div>\n</main>\n";
},"useData":true});
})();